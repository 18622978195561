var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FormulateForm",
        { ref: "formCoupon", attrs: { name: "formCoupon" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c(
                    "b-card-actions",
                    {
                      attrs: {
                        "no-actions": "",
                        title: _vm.$t("Configuração da NFCe"),
                      },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_nfce_tab-preferred_invoice_type",
                                  type: "vue-select",
                                  label: _vm.$t("Documento fiscal padrão"),
                                  placeholder: _vm.$t(
                                    "Selecione o documento fiscal padrão"
                                  ),
                                  options: _vm.payBox.availableInvoiceTypes,
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.preferredInvoiceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox,
                                      "preferredInvoiceType",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.preferredInvoiceType",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.isProduction
                            ? _c(
                                "b-col",
                                { attrs: { md: "4" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "pay_box_nfce_tab-submit_method",
                                      type: "vue-select",
                                      label: _vm.$t("Forma de emissão"),
                                      placeholder: _vm.$t("Forma de emissão"),
                                      options: _vm.submitMethodOptions,
                                      instruction: _vm.$t(
                                        "Caso selecionado 'Contingência' e loja for de SP, será utilizado a forma EPEC"
                                      ),
                                    },
                                    model: {
                                      value: _vm.payBox.nfce.submitMethod,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.payBox.nfce,
                                          "submitMethod",
                                          $$v
                                        )
                                      },
                                      expression: "payBox.nfce.submitMethod",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_nfce_tab-id_security_code",
                                  type: "text",
                                  label: _vm.$t("Id do código de segurança"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.nfce.idSecurityCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.nfce,
                                      "idSecurityCode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.nfce.idSecurityCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_nfce_tab-token_security_code",
                                  type: "text",
                                  label: _vm.$t("Token de segurança"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.nfce.tokenSecurityCode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.nfce,
                                      "tokenSecurityCode",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.nfce.tokenSecurityCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c("FormulateInput", {
                                staticClass: "required",
                                attrs: {
                                  id: "pay_box_nfce_tab-serial_number",
                                  type: "number",
                                  label: _vm.$t("Número de Série"),
                                  validation: "required",
                                },
                                model: {
                                  value: _vm.payBox.nfce.serialNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payBox.nfce,
                                      "serialNumber",
                                      $$v
                                    )
                                  },
                                  expression: "payBox.nfce.serialNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }